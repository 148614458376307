import React from 'react';

import { Layout } from '../components/layout';
import { MenuLink } from '../components/menu';
import { Title, Paragraph } from '../components/copy';

const Mentorschap = ({ location }) => (
  <Layout location={location}>
    <div className="mb-4">
      <MenuLink to="/">{'< Terug naar homepagina'}</MenuLink>
    </div>

    <Title>Mentorschap</Title>

    <Paragraph>
      Een mentor komt op voor de persoonlijke (niet-financiële) belangen.
      Wanneer dit nodig is neemt de mentor besluiten m.b.t zaken als verpleging,
      behandeling en begeleiding. De mentor doet dit zoveel mogelijk in
      samenspraak met de betrokkene en houdt daarbij zoveel mogelijk rekening
      met diens normen, waarden en levensovertuiging. Betrokkene en mentor
      hebben regelmatig contact, zodat de mentor goed op de hoogte is van de
      actuele situatie van betrokkene. We starten altijd met een
      kennismakingsgesprek, waarin e.e.a. wordt uitgelegd en de verdere aanvraag
      voor mentorschap wordt besproken.
    </Paragraph>

    <Paragraph>
      Voor aanmelding kunt u contact opnemen met één van ons.
    </Paragraph>
  </Layout>
);

export default Mentorschap;
